import React from "react";
import { Text, Box } from "theme-ui";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import NavLink from "../components/NavLink";

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />
		<Box sx={{ textAlign: "center", p: 4 }}>
			<Text variant="headingMedium" as="h1">
				Sorry, it looks like that page doesn&#39;t exist
			</Text>
			<NavLink to="/">Visit home</NavLink>
		</Box>
	</Layout>
);

export default NotFoundPage;
